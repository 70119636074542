<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group label="Invoice Status">
          <b-form-input
            disabled
            :value="queryResult.invoice_status"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Invoice No">
          <b-form-input
            disabled
            :value="queryResult.invoice_no"
          />
        </b-form-group>
      </b-col>

    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Invoice Ref">
          <b-form-input
            disabled
            :value="queryResult.invoice_ref"
          />
        </b-form-group>
      </b-col>

    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Reason">
          <b-form-input
            disabled
            :value="queryResult.fpx_debit_auth_code + ' - ' + queryResult.fpx_debit_status"
          />
        </b-form-group>
      </b-col>

    </b-row>

    <b-btn @click="copyAsTelegram(queryResult)">
      Copy for Telegram
    </b-btn>
  </div>
</template>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {},
  props: {
    queryResult: {
      type: Object,
      default: () => ({
        merchant_invoice_no: '',
        invoice_ref: '',
        invoice_no: '',
        provider_name: '',
        provider_category_code: '',
        provider_type_reference: '',
        fpx_debit_auth_code: '',
        fpx_debit_status: '',
        invoice_status: '',
        created_at: '',
      }),
    },
  },
  methods: {
    copyAsTelegram(selectedRowData) {
      if (selectedRowData.invoice_status === 'SUCCESS') {
        const successContent = `${selectedRowData.invoice_ref} \nStatus: ${selectedRowData.invoice_status}`
        navigator.clipboard.writeText(successContent)
      } else {
        const content = `${selectedRowData.invoice_ref} \nStatus: ${selectedRowData.invoice_status} \nReason: ${selectedRowData.fpx_debit_auth_code} - ${selectedRowData.fpx_debit_status}`
        navigator.clipboard.writeText(content)
        // this.doCopy2(content)
      }

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Telegram Text Copied',
          icon: 'BellIcon',
        },
      })
    },
    doCopy2(elem, elementName = null) {
      this.$copyText(elem).then(() => {
        console.log(elem)
        let label
        if (elementName) {
          label = elementName
        } else {
          label = 'Text'
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${label} copied`,
            icon: 'BellIcon',
          },
        })
      }, e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      })
    },
  },
}
</script>

<style>

</style>
