<template>
  <div>
    <lp-datatable
      id="adminCustomerBills"
      ref="adminCustomerBillsRef"
      action-condition="NESTED_INVOICE_NO"
      :extra-parameter="extraParam"
      title="Payment (Customer Bills)"
      :selectable-table="false"
      store-module="adminCustomerBills"
      store-data-endpoint="getCustomerBillsList"
      store-getter-endpoint="customerBillsList"
      pagination-state-key="customerBillsList"
      :features="loadedConfig.datatableFeatures"
      default-search-field="invoice_ref"
      default-sort-field="payment_date"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
      @actionBtn-clicked="actionBtnClicked"
      @reconcile-clicked="reconcileClicked"
      @bulk-reconcile-clicked="bulkReconcileClick"
      @extraBtn-clicked="extraBtnClicked"
      @status-filter-btn-clicked="statusFilterBtnClicked"
      @payment-provider-btn-clicked="paymentProviderFilterBtnClicked"
    >
      <template #admin_action="row">
        <!--        <b-button-->
        <!--          size="sm"-->
        <!--          @click="copyStatus(row.data)"-->
        <!--        >-->
        <!--          Telegram-->
        <!--        </b-button>-->

        <b-button
          size="sm"
          @click="getPSTxnStatus(row.data.CustomerBill)"
        >
          Get PS Status
        </b-button>
      </template>
    </lp-datatable>

    <b-modal
      id="psStatusCheckModal"
      ref="psStatusCheckModalRef"
      hide-footer
      header-bg-variant="primary"
      header-text-variant="light"
      header-close-variant="dark"
      title="PS Check Status"
    >

      <ps-status-check-modal-form :query-result="psStatusCheckData" />

    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import Config from '@/views/administration/customer-bills/formConfig'
import humaniseMixin from '@/common/humanise.mixin'
import LpDatatable from '@/leanpay-components/LPDataTable.vue'
import PsStatusCheckModalForm from '@/views/administration/customer-bills/components/psStatusCheckModalForm.vue'

export default {
  components: { PsStatusCheckModalForm, LpDatatable },
  mixins: [humaniseMixin],
  data() {
    return {
      currentRowData: {},
      cardTitle: 'Customer Bills',
      dataGridEndpoint: [],
      module: 'adminCustomerBills',
      psStatusCheckData: {},

      extraParam: {
        invoice_status: ['SUCCESS', 'PENDING', 'FAILED', 'VOID_PAYMENT'],
        payment_provider: 'SWITCH_WEBPAY',
      },
    }
  },
  computed: {
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
  },
  async mounted() {
    this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Payment (Customer Bills)' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
    await this.$store.dispatch('systemConstants/getSystemConstants')

    this.$refs.adminCustomerBillsRef.tablePaymentProviderFilterButtonClick('paymentProviderFilterWebpay', 1)
  },
  methods: {
    statusFilterBtnClicked(e) {
      if (e === 'allFilter') {
        this.extraParam.invoice_status = ['SUCCESS', 'PENDING', 'FAILED', 'VOID_PAYMENT']
        this.$refs.adminCustomerBillsRef.initDataGrid()
      }
      if (e === 'successFilter') {
        this.extraParam.invoice_status = ['SUCCESS']
        this.$refs.adminCustomerBillsRef.initDataGrid()
      }
      if (e === 'pendingFilter') {
        this.extraParam.invoice_status = ['PENDING']
        this.$refs.adminCustomerBillsRef.initDataGrid()
      }
      if (e === 'failedFilter') {
        this.extraParam.invoice_status = ['FAILED']
        this.$refs.adminCustomerBillsRef.initDataGrid()
      }
      if (e === 'voidFilter') {
        this.extraParam.invoice_status = ['VOID_PAYMENT']
        this.$refs.adminCustomerBillsRef.initDataGrid()
      }
    },
    getPSTxnStatus(customerBills) {
      console.log(customerBills)
      const payload = {
        transaction_invoice_no: customerBills.transaction_invoice_no,
      }

      this.$store.dispatch('adminCustomerBills/fpxFailedReason', payload).then(res => {
        if (res.data.response_code === 2000) {
          this.psStatusCheckData = {
            invoice_ref: customerBills.invoice_ref,
            ...res.data.data,
          }
          this.$refs.psStatusCheckModalRef.show()
        }
      })
    },

    copyStatus(rowData) {
      if (rowData.CustomerBill.invoice_status === 'SUCCESS') {
        this.doCopy(`${rowData.CustomerBill.invoice_ref} \nStatus: ${rowData.CustomerBill.invoice_status}`)
      } else {
        const content = `${rowData.CustomerBill.invoice_ref} \nStatus: ${rowData.CustomerBill.invoice_status} \nReason: ${rowData.fpx_debit_auth_code} - ${rowData.fpx_debit_status}`
        this.doCopy(content)
      }
    },
    extraBtnClicked(e) {},
    actionBtnClicked(e) {},
    reconcileClicked(e) {
      const defaultParam = {
        title: 'Confirmation',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        html: 'Reconcile item?',
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'leanx-swal',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      }
      Vue.swal(defaultParam).then(result => {
        if (result.isConfirmed) {
          const temp = []
          temp.push(e)

          const payload = {
            _invoices: temp,
          }
          this.$store.dispatch('settlement/bulkReconcile', payload).then(res => {
            if (res.data.response_code === 2000) {
              this.$refs.adminCustomerBillsRef.initDataGrid()

              this.$swal.fire({
                customClass: {
                  confirmButton: 'btn btn-sm btn-primary',
                },
                html: 'Recon Successful',
                title: 'Notification',
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: 'Okay',
              })
            }
          })
        }
      })
    },
    bulkReconcileClick(e) {
      const temp = []
      if (e.length > 0) {
        e.forEach(item => { temp.push(item.CustomerBill.invoice_no) })
      }

      const defaultParam = {
        title: 'Confirmation',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        html: `Reconcile ${e.length} item?`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'leanx-swal',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      }
      Vue.swal(defaultParam).then(result => {
        if (result.isConfirmed) {
          const payload = {
            _invoices: temp,
          }
          this.$store.dispatch('settlement/bulkReconcile', payload).then(res => {
            if (res.data.response_code === 2000) {
              this.$refs.adminCustomerBillsRef.initDataGrid()

              this.$swal.fire({
                customClass: {
                  confirmButton: 'btn btn-sm btn-primary',
                },
                html: 'Recon Successful',
                title: 'Notification',
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: 'Okay',
              })
            }
          })
        }
      })
    },
    paymentProviderFilterBtnClicked(e) {
      if (e === 'paymentProviderFilterNone') {
        this.extraParam.payment_provider = null
        this.$refs.adminCustomerBillsRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterWebpay') {
        this.extraParam.payment_provider = 'SWITCH_WEBPAY'
        this.$refs.adminCustomerBillsRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterBNPL') {
        this.extraParam.payment_provider = 'SWITCH_BNPL'
        this.$refs.adminCustomerBillsRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterCC') {
        this.extraParam.payment_provider = 'SWITCH_CC'
        this.$refs.adminCustomerBillsRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterCrypto') {
        this.extraParam.payment_provider = 'SWITCH_CRYPTOCURRENCY'
        this.$refs.adminCustomerBillsRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterEwallet') {
        this.extraParam.payment_provider = 'SWITCH_EWALLET'
        this.$refs.adminCustomerBillsRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterCwallet') {
        this.extraParam.payment_provider = 'SWITCH_CWALLET'
        this.$refs.adminCustomerBillsRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterPaypal') {
        this.extraParam.payment_provider = 'SWITCH_PAYPAL'
        this.$refs.adminCustomerBillsRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterStripe') {
        this.extraParam.payment_provider = 'SWITCH_STRIPE_CC'
        this.$refs.adminCustomerBillsRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterCCEmbed') {
        this.extraParam.payment_provider = 'SWITCH_STRIPE_CC_EMBED'
        this.$refs.adminCustomerBillsRef.initDataGrid()
      }
    },
  },
}
</script>
