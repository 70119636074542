import { vm } from '@/main'

const Config = {
  datatableFeatures: {
    tablePaymentProviderFilterButton: [
      {
        // SWITCH_WEBPAY
        buttonLabel: 'All',
        buttonRef: 'paymentProviderFilterNone',
        active: false,
      },
      {
        // SWITCH_WEBPAY
        buttonLabel: 'FPX',
        buttonRef: 'paymentProviderFilterWebpay',
        active: true,
      },
      {
        // SWITCH_BNPL
        buttonLabel: 'BNPL',
        buttonRef: 'paymentProviderFilterBNPL',
        active: false,
      },
      {
        // SWITCH_CC
        buttonLabel: 'Credit Card',
        buttonRef: 'paymentProviderFilterCC',
        active: false,
      },
      {
        // SWITCH_STRIPE_CC
        buttonLabel: 'Stripe',
        buttonRef: 'paymentProviderFilterStripe',
        active: false,
      },
      {
        // SWITCH_EWALLET
        buttonLabel: 'BoldPay E-wallet',
        buttonRef: 'paymentProviderFilterCwallet',
        active: false,
      },
      {
        // SWITCH_EWALLET
        buttonLabel: 'SenangPay E-wallet',
        buttonRef: 'paymentProviderFilterEwallet',
        active: false,
      },
      {
        // SWITCH_CRYPTOCURRENCY
        buttonLabel: 'Cryptocurrency',
        buttonRef: 'paymentProviderFilterCrypto',
        active: false,
      },
      {
        // SWITCH_PAYPAL
        buttonLabel: 'Paypal',
        buttonRef: 'paymentProviderFilterPaypal',
        active: false,
      },
      {
        // SWITCH_STRIPE_CC_EMBED
        buttonLabel: 'CC Embed',
        buttonRef: 'paymentProviderFilterCCEmbed',
        active: false,
      },

    ],
    tableStatusFilterButton: [
      {
        buttonLabel: 'All',
        buttonRef: 'allFilter',
        active: true,
      },
      {
        buttonLabel: 'Pending',
        buttonRef: 'pendingFilter',
        active: false,
      },

      {
        buttonLabel: 'Success',
        buttonRef: 'successFilter',
        active: false,
      },
      {
        buttonLabel: 'Failed',
        buttonRef: 'failedFilter',
        active: false,
      },
      {
        buttonLabel: 'Void',
        buttonRef: 'voidFilter',
        active: false,
      },
    ],
    buttons: {
      createButton: false,
      createButtonRoute: 'administration-accounts-new',
      refresh: true,
    },
    datepicker: true,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'transaction_invoice_no', text: 'TXN Invoice #' },
    { value: 'invoice_no', text: 'Invoice #' },
    { value: 'full_name', text: 'Customer Name' },
    { value: 'email', text: 'Customer Email' },
    { value: 'invoice_ref', text: 'Invoice Ref' },
    { value: 'phone_number', text: 'Customer Phone Number' },
  ],
  formData() {
    return {
      title: {
        edit: 'Edit Bank',
        view: 'View Bank',
        remove: 'Remove Bank',
        create: 'Create New Bank',
      },
      form: [{
        formContext: 'Account',
        formName: 'account',
        elem: [
          {
            key: 'name',
            fieldName: 'Name',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'code',
            fieldName: 'Code',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'bank_branch',
            fieldName: 'Bank Branch',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'account_number',
            fieldName: 'Account Number',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'bank_account_statement',
            fieldName: 'Bank Account Statement',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'description',
            fieldName: 'Description',
            type: 'textarea',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            customValidation: true,
            key: 'record_status',
            fieldName: 'Active?',
            type: 'switch',
            trueLabel: 'Active',
            falseLabel: 'Inactive',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            rules: '',
            colSize: {
              lg: 12,
            },
          },
        ],
      }],
    }
  },
  tableData() {
    return {
      tableAction: [
        {
          enable: false, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        {
          key: 'show_details', label: '', sortable: false, visible: true, type: 'showDetails', class: 'show-details col-width',
        },
        {
          key: 'CustomerBill.payment_date', label: 'Payment Date', sortable: false, visible: true, type: 'nestedKeyDatetime',
        },
        {
          key: 'CustomerBill.id', label: 'Bill ID', sortable: false, visible: false, type: 'nestedKey', showInDetails: true,
        },
        {
          key: 'account_name', label: 'Merchant Account Name', sortable: false, visible: true, showInDetails: true,
        },

        {
          key: 'CustomerBill.invoice_no', label: 'Invoice #', sortable: false, visible: false, type: 'nestedKey', showInDetails: true,
        },
        {
          key: 'CustomerBill.invoice_ref', label: 'Invoice Ref', sortable: false, visible: true, type: 'nestedKey',
        },
        {
          key: 'CustomerBill.transaction_invoice_no', label: 'TXN Invoice #', sortable: false, visible: true, type: 'nestedKey',
        },

        {
          key: 'CustomerBill.payment_provider', label: 'Payment Provider', sortable: false, visible: true, type: 'nestedKey', showInDetails: true,
        },
        {
          key: 'payment_mode', label: 'Payment Mode', sortable: false, visible: false, showInDetails: true,
        },
        {
          key: 'fpx_debit_auth_code', label: 'Debit Auth Code', sortable: false, visible: false, showInDetails: true,
        },
        {
          key: 'fpx_debit_status', label: 'Debit Status', sortable: false, visible: false, showInDetails: true,
        },
        {
          key: 'payment_method', label: 'Payment Method', sortable: false, visible: true, showInDetails: true,
        },
        {
          key: 'CustomerBill.total', label: 'Total Amount', sortable: false, visible: true, type: 'nestedKeyMonetary',
        },
        {
          key: 'amount_fee', label: 'Amount Fee', sortable: false, visible: true, type: 'monetary', showInDetails: false,
        },
        {
          key: 'postpaid_amount_fee', label: 'Postpaid Fee', sortable: false, visible: false, type: 'monetary', showInDetails: true,
        },

        {
          key: 'CustomerBill.invoice_status', label: 'Status', sortable: false, visible: true, type: 'nestedKeyStatus',
        },
        // show in details
        {
          key: 'CustomerBill.full_name', label: 'Customer Name', sortable: false, visible: false, type: 'nestedKey', showInDetails: true,
        },
        {
          key: 'CustomerBill.email', label: 'Customer Email', sortable: false, visible: false, type: 'nestedKey', showInDetails: true,
        },
        {
          key: 'CustomerBill.phone_number', label: 'Customer Phone', sortable: false, visible: false, type: 'nestedKey', showInDetails: true,
        },

        {
          key: 'merchant_id', label: 'Merchant Id', sortable: false, visible: false, showInDetails: true,
        },
        {
          key: 'name', label: 'Merchant Name', sortable: false, visible: false, showInDetails: true,
        },
        {
          key: 'CustomerBill.is_recon', label: 'Recon?', sortable: false, visible: false, type: 'nestedKey', showInDetails: true,
        },

        // {
        //   key: 'updated_at', label: 'Updated At', sortable: true, visible: false,
        // },
        {
          key: 'created_at', label: 'Created At', sortable: false, visible: false,
        },
        {
          key: 'admin_action',
          label: 'Admin Action',
          visible: !!vm.IS_ADMIN_PORTAL,
        },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: true,
        },
      ],
    }
  },
}

export default Config
